@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: "Uni Neue-Trial", "Roboto", sans-serif; */
  font-family: 'Noto Sans KR', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

li {
  list-style-type: disc;
}

/* Custom font  */
@font-face {
    src: local("Uni Neue-Trial"),
      url("./font/UniNeue-Trial-Regular.ttf") format("truetype");
  }

/* containers  */
.main-container {
  @apply w-10/12 mx-auto max-w-[1448px];
  white-space: pre-wrap;

}

.section-container {
  @apply py-[100px] lg:py-[170px];
}

/* fonts  */
.theme-h1 {
  @apply text-[32px] leading-[2.5rem] lg:text-6xl font-bold;
}

.theme-h2 {
  @apply text-3xl lg:text-5xl font-bold;
}

.theme-h3 {
  @apply text-2xl lg:text-3xl font-bold;
}

.theme-h4 {
  @apply text-xl lg:text-2xl font-semibold;
}

.theme-p1 {
  @apply text-lg lg:text-2xl;
}

.theme-p2 {
  @apply text-base lg:text-xl;
}

.theme-smtext {
  @apply text-lg;
}

.theme-values-num {
  font-family: "Uni Neue-Trial";
  @apply font-bold text-6xl lg:text-8xl;
}

.theme-num {
  font-family: "Uni Neue-Trial";
  @apply font-bold text-4xl lg:text-8xl;
}

/* ------ */
.nav-link-lg {
  @apply theme-smtext hidden lg:flex hover:border-b pb-[1px] ease-out duration-200;
}

.nav-link-mbl {
  @apply theme-h2 hover:border-b pb-[1px] ease-out duration-200;
}

.theme-btn {
  @apply w-max text-lg lg:text-2xl font-bold px-10 lg:px-14 py-[10px] lg:py-3 ease-out duration-200;
}

.theme-input {
  @apply px-3 lg:px-5 py-1 lg:py-2 border border-theme-dark  text-theme-gray outline-none;
}

/* overriding toast css  */
.Toastify__toast-container {
  z-index: 99999999999!important;
}
.Toastify__toast-container--top-center {
  top: 5em!important;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 80vw!important;
  }
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
  animation-direction: reverse!important;
}